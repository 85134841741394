@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  p {
    margin: 0;
  }
}

.container {
  display: grid;
  row-gap: pxToRem(32);
  margin: auto;
  padding: 0;
  list-style: none;
  text-align: center;
}

.header {
  text-align: center;
  padding-bottom: pxToRem(32);

  h3 {
    color: var(--tex-def-tie1);
    margin: 0;
  }
}

.imgContainer {
  width: 100%;
  aspect-ratio: 1/1;
  position: relative;
  text-align: center;
  border-radius: var(--cor_rad-xs);
  overflow: hidden;

  &.halfHeightImgContainer {
    aspect-ratio: 2/1;
  }
}

.img {
  width: 100%;
  height: 100%;
  display: block;
  object-position: center;

  &.imgContain {
    object-fit: contain;
  }
  &.imgCover {
    object-fit: cover;
    height: 100%;
  }
}

.title {
  padding: pxToRem(24) 0 pxToRem(8);
  white-space: pre-line;
}

.contentTitle {
  padding: pxToRem(24) 0 pxToRem(8);
}

.text {
  padding-bottom: pxToRem(24);
  word-break: break-word;
}

.buttonContainer {
  :global {
    .MuiButton-root {
      padding: pxToRem(14) pxToRem(12) pxToRem(15);
    }
  }
}

.horizontalContentContainer {
  display: grid;
  grid-template-columns: pxToRem(156) auto;
  gap: pxToRem(24);
  text-align: left;

  .title {
    padding-top: 0;
  }

  .content {
    max-width: 52%;
  }
}

.imgLink:hover {
  cursor: pointer;
}

.tagLabelContainer {
  margin-bottom: pxToRem(8);

  .tagLabel {
    position: static;
    justify-content: center;
  }
}

@include sm {
  .root {
    grid-row: pxToRem(520);

    .title {
      max-width: pxToRem(379);
      margin: auto;
    }

    .text {
      max-width: pxToRem(478);
      margin: auto;
    }
  }

  .container {
    max-width: pxToRem(520);
  }

  .horizontalContentContainer {
    display: block;
    text-align: center;

    .title {
      padding: pxToRem(24) 0 pxToRem(8);
    }

    .content {
      max-width: 100%;
    }
  }
}

@include md {
  .root {
    .title {
      font-weight: 800;
    }
  }

  .header {
    padding-bottom: pxToRem(48);
    font-weight: 800;
  }

  .container {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    column-gap: pxToRem(48);
    max-width: 100%;
  }

  .buttonAlign {
    position: relative;

    .buttonContainer {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .text {
      padding-bottom: pxToRem(62);
    }
  }

  .tagLabelContainer {
    margin-bottom: pxToRem(24);
  }
}

@include lg {
  .root {
    .title {
      max-width: 100%;
    }

    .text {
      max-width: 100%;
    }
  }
  .container {
    column-gap: pxToRem(56);
    max-width: 100%;
  }

  .title {
    padding-bottom: pxToRem(16);
  }
}

@include xl {
  .container {
    column-gap: pxToRem(52);
    max-width: 100%;
  }

  .header {
    padding-bottom: pxToRem(80);
  }
}

/**
 * TODO - refactor
 * Taken from Callout.module.scss.
 */
.callOutSection {
  .container {
    row-gap: pxToRem(48);
  }

  .imgContainer {
    width: 50%;
    height: pxToRem(80);
    margin: auto;
  }

  .title {
    padding: pxToRem(16) 0 pxToRem(8);
  }

  .text {
    padding-bottom: pxToRem(16);
  }
}

@include md {
  .callOutSection {
    .container {
      column-gap: pxToRem(16);
    }

    .imgContainer {
      width: 60%;
      height: pxToRem(100);
    }
  }
}

@include lg {
  .callOutSection {
    .container {
      column-gap: pxToRem(64);
    }
  }
}

@include xl {
  .callOutSection {
    .container {
      column-gap: pxToRem(104);
    }
  }
}
